import Image from 'next/image'
import Link from 'next/link'
import { forwardRef } from 'react'

import LoginButton from '../../../components/login/LoginButton'
import { useIsDesktop } from '../../../hooks/useIsDesktop'
import { useAppSelector } from '../../../redux/store/store'
import classes from '../../../styles/Home.module.css'
import { curatorCount } from '../utils'
import Taglines from './tagline'

interface LoggedOutHeroProps {
  isHeroInView: boolean
}

export const LoggedOutHero = forwardRef<HTMLDivElement | null, LoggedOutHeroProps>(({ isHeroInView }, ref) => {
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isDesktop = useIsDesktop()
  const { element: TaglineElement } = Taglines[currentPath as keyof typeof Taglines] ?? Taglines.default

  return (
    <>
      <div className="relative flex min-h-[70vh] flex-col overflow-hidden bg-black" ref={ref}>
        <div className="container relative z-10 mx-auto flex h-20 shrink-0 items-center justify-center px-6">
          <Link href="/">
            <Image src="/logo_white.svg" alt="Mix" height={40} width={120} />
          </Link>
        </div>
        <div className="absolute inset-0 flex items-center justify-center transition-all sm:top-4 md:top-40 lg:top-56">
          <video
            muted
            autoPlay
            hidden={!isHeroInView}
            loop
            playsInline
            className="mx-auto mt-[-30vh] scale-[2] md:scale-125 lg:mt-[-15vh] xl:mt-[-20vh]"
          >
            <source src="https://assets.mix.com/static/onboarding/intro_desktop.webm" type="video/webm" />
            <source src="https://assets.mix.com/static/onboarding/intro_desktop.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="relative flex flex-1 flex-col items-center justify-center lg:pt-10">
          <div className="relative px-6 pt-24">
            <div className="relative">
              <div className={classes.animateFlyLongReverse}>
                <div className="relative z-10 text-center transition-opacity">
                  <TaglineElement />
                  <div className="mb-8">
                    <LoginButton className="btn btn-primary btn-dot">
                      {isDesktop ? 'Join Mix' : 'Get the Mix App'}
                    </LoginButton>
                  </div>
                  <div className="mx-auto mb-12 max-w-[65vw] text-xs font-medium uppercase tracking-wider text-accent">
                    Explore some of the gems discovered by <span className="proportional-nums">{curatorCount()}</span>{' '}
                    curators
                  </div>
                </div>
                <span className="absolute inset-x-0 top-0 h-24 bg-black blur-2xl"></span>
                <span className="absolute inset-0 bg-black opacity-50 blur-2xl"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

LoggedOutHero.displayName = 'LoggedOutHero'
