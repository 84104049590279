import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { GridFeed } from '../../../components/layouts/GridFeedlLayout'
import feedApi from '../../../redux/api/feedApi'
import { selectMediaTypes } from '../../../redux/slices/appSlice'
import { incrementPage, selectFilteredIds, setCurrentFeedItemKey } from '../../../redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store/store'
import { FeedFilters } from '../../node/components/FeedFilters'

const LoggedInHomeMobile = () => {
  const { identifier, page, pageSize, _instanceId } = useAppSelector(state => state.feed)
  const { slug } = identifier

  // useEffect(() => {
  //   changePageContext(
  //     new PageContext({
  //       slug: { value: slug },
  //       page: PageType.TOPIC_PROFILE_PAGES,
  //       node: { value: slug },
  //       pageSection,
  //     })
  //   )
  // }, [slug, changePageContext, pageSection])

  const mediaTypes = useAppSelector(selectMediaTypes)
  const filterIds = useAppSelector(selectFilteredIds)

  const { data: feed, isLoading: isLoadingFeed } = feedApi.useGetFeedDataQuery({
    page,
    pageSize,
    slug,
    mediaTypes: Array.from(mediaTypes),
    filterIds,
    _instanceId,
    contextId: null,
  })
  const { items } = feed ?? {}
  const router = useRouter()
  const dispatch = useAppDispatch()
  const onGridItemClick = useCallback(
    (feedItemKey: string) => {
      const queryString = `?skipSSR=true`
      const asPath = `/!${feedItemKey}`
      const href = `/!${feedItemKey}${queryString}`
      dispatch(setCurrentFeedItemKey(feedItemKey))
      router.push(href, asPath).catch(() => {})
    },
    [router, dispatch]
  )

  const loadMore = useCallback(() => {
    dispatch(incrementPage(1))
  }, [dispatch])

  return (
    <>
      <div className="my-8 flex flex-col items-center space-y-4">
        <p className="text-4xl font-bold">For You</p>
        <FeedFilters />
      </div>
      <div className="flex size-full flex-col overflow-y-scroll scrollbar-hide md:flex-row" id="scrolling-grid">
        <GridFeed loadMore={loadMore} items={items} onClick={onGridItemClick} isLoadingFeed={isLoadingFeed} />
      </div>
    </>
  )
}

export default LoggedInHomeMobile
