import { useRouter } from 'next/router'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import runtimeConfig from '../../../lib/RuntimeConfig'
import UrlHelper from '../../../lib/UrlHelper'
import { filterResourcesByType } from '../../../models/RecommendResponse'
import feedApi from '../../../redux/api/feedApi'
import { selectMediaTypes } from '../../../redux/slices/appSlice'
import { feedConstants, selectFilteredIds, setCurrentFeedItemKey } from '../../../redux/slices/feedSlice'
import { useAppSelector } from '../../../redux/store/store'

const LoggedInHomeDesktop = () => {
  const {
    identifier: { slug },
    hasReachedEnd,
    _instanceId,
  } = useAppSelector(state => state.feed)
  const mediaTypes = useAppSelector(selectMediaTypes)
  const filterIds = useAppSelector(selectFilteredIds)

  const { data: feed } = feedApi.useGetFeedDataQuery(
    {
      page: 1,
      pageSize: feedConstants.FULL_SCREEN_PAGE_SIZE,
      slug,
      mediaTypes: Array.from(mediaTypes),
      filterIds,
      _instanceId,
      contextId: null,
    },
    { skip: hasReachedEnd }
  )

  const { items } = feed ?? {}

  const firstUrlID = useMemo(() => {
    const urls = filterResourcesByType(items, 'url')
    return urls[0]?.url_id
  }, [items])

  const dispatch = useDispatch()
  const { isAddUrlModalOpen } = useAppSelector(state => state.addUrlModal)
  const router = useRouter()
  const parsedURL = new URL(runtimeConfig().publicRuntimeConfig.baseUrl + router.asPath)
  const pathname = parsedURL.pathname.substring(1)

  useEffect(() => {
    if (firstUrlID && !isAddUrlModalOpen) {
      dispatch(setCurrentFeedItemKey(firstUrlID))
      router
        .push(UrlHelper.urlIDPath({ urlID: firstUrlID, skipSSR: true }), UrlHelper.urlIDPath({ urlID: firstUrlID }))
        .catch(() => {})
    }
  }, [dispatch, router, firstUrlID, pathname, isAddUrlModalOpen])

  return <></>
}

export default LoggedInHomeDesktop
